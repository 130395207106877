export enum Status {
  NewOpportunity = 'New Opportunity',
  Contacted = 'Contacted',
  InProgress = 'In Progress',
  Pipeline = 'Pipeline',
  GoneQuiet = 'Gone Quiet',
  Uncontactable = 'Uncontactable',
  ClosedWon = 'Closed Won',
  ClosedLost = 'Closed Lost',
  Unqualified = 'Unqualified',
}

export enum Opportunity {
  NewLandlordSignup = 'New Landlord Signup',
  TenantMovingIn = 'Tenant Moving In',
  TenantMoveOut = 'Tenant Move-out',
  NewPropertyListing = 'New Property Listing',
  OfferForm = 'Offer Form',
  PropertyUnderContract = 'Property Under Contract',
  PropertyUnconditional = 'Property Unconditional',
  PropertySettled = 'Property Settled',
}

export enum Partner {
  MortgageBroking = 'Mortgage Broking',
  Utilities = 'Utilities',
  Insurance = 'Insurance',
  Conveyancing = 'Conveyancing',
}

export type RowData = {
  name: string
  status: Status
  opportunity: Opportunity
  partner: Partner
  staff: string
  property: string
  date: string
}
