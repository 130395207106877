import React, { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { Box, FormControl, FormControlLabel, FormGroup } from '@mui/material'
import { FLAG_FEATURES } from 'api/src/common/enums'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import AncilTable from 'src/components/Ancil/AncilTable'
import CreateLeadModal from 'src/components/Ancil/LeadModal'
import TabNavigation from 'src/components/Ancil/TabNavigation'
import Button from 'src/components/Library/Button/Button'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import Switch from 'src/components/Library/Switch/Switch'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

import PageHeader from '../../components/PageHeader/PageHeader'

const AncilPage = () => {
  const [showNonConsentOnly, setShowNonConsentOnly] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const queryParams = new URLSearchParams(location.search)
  const currentTab = queryParams.get('tab') || 'leads'

  const handleTabChange = (newTab: string) => {
    navigate(`${routes.ancil()}?tab=${newTab}`)
  }

  const handleCreateLead = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Metadata title="Ancillary Services" description="Ancillary Services" />
      <PageHeader
        title="Ancillary Services"
        parentDataTestId="audit-log-page"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.ANCIL}
        InactiveComponent={<FeatureDenied />}
      >
        <Box
          p={3}
          pb={2}
          className={'flex w-full items-center justify-between'}
        >
          <Box className={'flex'} gap={3}>
            <Box>
              <SearchField
                value={''}
                onChange={() => null}
                className={'!w-[350px]'}
              />
            </Box>
            <Box className={'-mt-2'}>
              {/* BLOCKED - Grouping rows requires AG Grid enterprise. Will turn back on in Jan '25 */}
              {/*<TabNavigation tab={currentTab} onTabChange={handleTabChange} />*/}
            </Box>
          </Box>
          <Box className={'flex'} gap={3}>
            <Box>
              <FormControl className={'-mr-3'}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showNonConsentOnly}
                        onChange={() =>
                          setShowNonConsentOnly(!showNonConsentOnly)
                        }
                        name="admin"
                        className={'mr-2'}
                      />
                    }
                    label={'Non Consent Only'}
                  />
                </FormGroup>
              </FormControl>
            </Box>
            <Box>
              <Button
                className={'px-10 capitalize'}
                variant={'contained'}
                startIcon={<AddIcon />}
                disableRipple
                onClick={() => setIsModalOpen(true)}
              >
                {'Create Lead'}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box p={3} pt={0}>
          <AncilTable />
        </Box>
      </FeatureToggle>
      <CreateLeadModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateLead}
      />
    </>
  )
}

export default AncilPage
