import React from 'react'

import { Chip } from '@mui/material'

import { Partner } from 'src/components/Ancil/types'

interface Props {
  partner: Partner
}

export default function PartnerChip({ partner }: Props): React.ReactElement {
  const partnerClassNames: Record<Partner, string> = {
    [Partner.MortgageBroking]: 'bg-orange-100 text-orange-600',
    [Partner.Utilities]: 'bg-green-100 text-green-600',
    [Partner.Insurance]: 'bg-indigo-100 text-indigo-600',
    [Partner.Conveyancing]: 'bg-blue-100 text-blue-600',
  }

  return (
    <Chip
      size={'small'}
      label={partner}
      className={`rounded-xl px-1 py-2 ${partnerClassNames[partner] || 'bg-gray-300 text-black'}`}
    />
  )
}
