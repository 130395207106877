import React from 'react'

import { Avatar, Box, Typography } from '@mui/material'

interface Props {
  name: string
}

export default function StaffChip({ name }: Props): React.ReactElement {
  const getInitials = (fullName: string): string => {
    const nameParts = fullName.trim().split(' ')
    const firstInitial = nameParts[0]?.[0]?.toUpperCase() || ''
    const lastInitial =
      nameParts[nameParts.length - 1]?.[0]?.toUpperCase() || ''
    return `${firstInitial}${lastInitial}`
  }

  return (
    <Box className={'flex items-center'} gap={1.5}>
      <Avatar sx={{ width: 28, height: 28, fontSize: 12 }}>
        {getInitials(name)}
      </Avatar>
      <Typography variant={'body2'}>{name}</Typography>
    </Box>
  )
}
