import React from 'react'

import { Chip } from '@mui/material'

import { Opportunity } from 'src/components/Ancil/types'

interface Props {
  opportunity: Opportunity
}

export default function OpportunityChip({
  opportunity,
}: Props): React.ReactElement {
  const opportunityClassNames: Record<Opportunity, string> = {
    [Opportunity.NewLandlordSignup]: 'bg-green-100 text-green-600',
    [Opportunity.TenantMovingIn]: 'bg-indigo-100 text-indigo-600',
    [Opportunity.TenantMoveOut]: 'bg-red-100 text-red-600',
    [Opportunity.NewPropertyListing]: 'bg-indigo-100 text-indigo-600',
    [Opportunity.OfferForm]: 'bg-gray-100 text-gray-600',
    [Opportunity.PropertyUnderContract]: 'bg-blue-100 text-blue-600',
    [Opportunity.PropertyUnconditional]: 'bg-cyan-100 text-cyan-600',
    [Opportunity.PropertySettled]: 'bg-orange-100 text-orange-600',
  }

  return (
    <Chip
      size={'small'}
      label={opportunity}
      className={`rounded-xl px-1 py-2 ${opportunityClassNames[opportunity] || 'bg-gray-300 text-black'}`}
    />
  )
}
