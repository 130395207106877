import React, { useState } from 'react'

import { Button } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import LeadStatusChip from 'src/components/Ancil/LeadStatusChip'
import OpportunityChip from 'src/components/Ancil/OpportunityChip'
import PartnerChip from 'src/components/Ancil/PartnerChip'
import { RowData } from 'src/components/Ancil/types'
import { FAKE_DATA } from 'src/pages/AncilPage/fake'

import ReferLeadModal from './ReferLeadModal'
import StaffChip from './StaffChip'

const AncilTable = React.memo(function MemoizedAncilTable() {
  const [isReferModalOpen, setIsReferModalOpen] = useState(false)

  const colDefs: ColDef<RowData>[] = [
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Opportunity',
      field: 'opportunity',
      cellRenderer: (params: ICellRendererParams<RowData>) => (
        <OpportunityChip opportunity={params.value} />
      ),
    },
    {
      headerName: 'Property',
      field: 'property',
    },
    {
      headerName: 'Staff',
      field: 'staff',
      cellRenderer: (params: ICellRendererParams<RowData>) => (
        <StaffChip name={params.value} />
      ),
    },
    {
      headerName: 'Partner',
      field: 'partner',
      cellRenderer: (params: ICellRendererParams<RowData>) => (
        <PartnerChip partner={params.value} />
      ),
    },
    {
      headerName: 'Date',
      field: 'date',
    },
    {
      headerName: 'Status',
      field: 'status',
      cellRenderer: (params: ICellRendererParams<RowData>) => (
        <LeadStatusChip status={params.value} />
      ),
    },
    {
      headerName: 'Actions',
      flex: 0.5,
      cellRenderer: () => (
        <Button
          variant={'text'}
          size={'small'}
          className={'px-4 py-1 capitalize'}
          disableRipple
          onClick={() => setIsReferModalOpen(true)}
        >
          {'Refer Lead'}
        </Button>
      ),
      cellStyle: () => ({
        border: 'none', // Suppresses focus after clicking refer button
      }),
    },
  ]

  // This config is applied to all columns
  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      filter: false,
      resizable: true,
      cellClass: 'flex items-center',
      flex: 1,
    }),
    [],
  )

  return (
    <div
      className={'ag-theme-quartz'}
      style={{ height: 'calc(100vh - 230px)', width: '100%' }}
    >
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={FAKE_DATA as RowData[]}
        overlayNoRowsTemplate={'No Leads Available'}
        pagination={true}
        paginationPageSize={100}
        rowStyle={{ background: 'none' }}
        suppressRowHoverHighlight
        data-testid={'ancil-table'}
        rowBuffer={100}
        groupDisplayType="groupRows"
        gridOptions={{
          rowHeight: 50,
        }}
        groupDefaultExpanded={-1} // Expand all groups by default
      />
      <ReferLeadModal
        open={isReferModalOpen}
        onClose={() => setIsReferModalOpen(false)}
        onRefer={() => setIsReferModalOpen(false)}
      />
    </div>
  )
})

export default AncilTable
