import React from 'react'

import { Chip } from '@mui/material'

import { Status } from 'src/components/Ancil/types'

interface Props {
  status: Status
}

export default function LeadStatusChip({ status }: Props): React.ReactElement {
  const statusClassNames: Record<Status, string> = {
    [Status.NewOpportunity]: 'bg-indigo-100 text-indigo-600',
    [Status.Contacted]: 'bg-blue-100 text-blue-600',
    [Status.InProgress]: 'bg-blue-100 text-blue-600',
    [Status.Pipeline]: 'bg-red-100 text-red-600',
    [Status.GoneQuiet]: 'bg-red-100 text-red-600',
    [Status.Uncontactable]: 'bg-red-100 text-red-600',
    [Status.ClosedWon]: 'bg-green-100 text-green-600',
    [Status.ClosedLost]: 'bg-red-100 text-red-600',
    [Status.Unqualified]: 'bg-red-100 text-red-600',
  }

  return (
    <Chip
      size={'small'}
      label={status}
      className={`rounded-xl px-1 py-2 ${statusClassNames[status] || 'bg-gray-300 text-black'}`}
    />
  )
}
