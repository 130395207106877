export const FAKE_DATA = [
  {
    name: 'Alexis West',
    status: 'Closed Won',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Mrs. Jeanette Schowalter',
    property: '10 E South Street, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Wade Gutkowski',
    status: 'Closed Won',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Herman Sanford-Ratke',
    property: '56 Sid Glen, Perth NSW',
    date: '09-12-2024',
  },
  {
    name: 'Joey Prohaska',
    status: 'Pipeline',
    opportunity: 'Offer Form',
    partner: 'Conveyancing',
    staff: 'Richard Wuckert-Roberts',
    property: '94 Cristopher Burgs, Brisbane NSW',
    date: '09-12-2024',
  },
  {
    name: 'Sandy Prohaska Sr.',
    status: 'In Progress',
    opportunity: 'Tenant Moving In',
    partner: 'Mortgage Broking',
    staff: 'Grant Gorczany',
    property: '14 Lesch Pike, Adelaide VIC',
    date: '10-12-2024',
  },
  {
    name: 'Zachary Schulist MD',
    status: 'Pipeline',
    opportunity: 'Property Unconditional',
    partner: 'Utilities',
    staff: 'Edward Ondricka III',
    property: '10 E South Street, Perth VIC',
    date: '09-12-2024',
  },
  {
    name: 'Alexis West',
    status: 'Pipeline',
    opportunity: 'Property Under Contract',
    partner: 'Mortgage Broking',
    staff: 'Louise Gusikowski MD',
    property: '42 Schuppe Squares, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Wendy Kirlin',
    status: 'In Progress',
    opportunity: 'Property Settled',
    partner: 'Conveyancing',
    staff: 'Christine Stamm',
    property: '10 E South Street, Perth VIC',
    date: '09-12-2024',
  },
  {
    name: 'Alexis West',
    status: 'Pipeline',
    opportunity: 'New Property Listing',
    partner: 'Conveyancing',
    staff: 'Dr. Travis Gutkowski',
    property: '96 McCullough Points, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'Pipeline',
    opportunity: 'Tenant Moving In',
    partner: 'Insurance',
    staff: 'Ginger Daniel',
    property: '73 S Maple Street, Brisbane QLD',
    date: '10-12-2024',
  },
  {
    name: 'Sandy Prohaska Sr.',
    status: 'Closed Lost',
    opportunity: 'New Property Listing',
    partner: 'Utilities',
    staff: 'Heidi Leannon',
    property: '17 Windler Cape, Perth NSW',
    date: '10-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Gone Quiet',
    opportunity: 'Offer Form',
    partner: 'Insurance',
    staff: 'Merle Price',
    property: '83 Fern Centers, Melbourne QLD',
    date: '10-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'Closed Lost',
    opportunity: 'Offer Form',
    partner: 'Mortgage Broking',
    staff: 'Jerome Hilpert',
    property: '37 Mayert Centers, Perth SA',
    date: '09-12-2024',
  },
  {
    name: 'Nicholas Kutch',
    status: 'In Progress',
    opportunity: 'Property Under Contract',
    partner: 'Mortgage Broking',
    staff: 'Mrs. Kristi Lueilwitz',
    property: '95 Jordi Way, Adelaide VIC',
    date: '09-12-2024',
  },
  {
    name: 'Terrance Ernser III',
    status: 'New Opportunity',
    opportunity: 'New Landlord Signup',
    partner: 'Conveyancing',
    staff: 'Sean Keeling',
    property: '12 Ward Walks, Melbourne WA',
    date: '10-12-2024',
  },
  {
    name: 'Debra Carroll',
    status: 'Closed Lost',
    opportunity: 'Property Under Contract',
    partner: 'Utilities',
    staff: 'Enrique Schuster',
    property: '95 Little Key, Brisbane VIC',
    date: '10-12-2024',
  },
  {
    name: 'Toby Jacobson',
    status: 'Unqualified',
    opportunity: 'Offer Form',
    partner: 'Mortgage Broking',
    staff: 'Cheryl Hyatt',
    property: '31 Sharon Mission, Brisbane NSW',
    date: '10-12-2024',
  },
  {
    name: 'Toby Jacobson',
    status: 'Closed Won',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Misty DuBuque',
    property: '97 Doris Cove, Sydney WA',
    date: '09-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'Gone Quiet',
    opportunity: 'Property Under Contract',
    partner: 'Insurance',
    staff: 'Louis Fahey',
    property: '81 Collier Drive, Brisbane VIC',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'New Opportunity',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Orlando Hansen',
    property: '66 S Railroad Street, Sydney NSW',
    date: '10-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'Unqualified',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Antonio Littel-Mosciski',
    property: '71 Derwent Close, Adelaide NSW',
    date: '10-12-2024',
  },
  {
    name: 'Wendy Kirlin',
    status: 'Closed Won',
    opportunity: 'New Property Listing',
    partner: 'Utilities',
    staff: 'Tabitha Renner',
    property: '98 Brook Road, Sydney QLD',
    date: '10-12-2024',
  },
  {
    name: 'Dora Krajcik',
    status: 'New Opportunity',
    opportunity: 'New Landlord Signup',
    partner: 'Mortgage Broking',
    staff: 'Sheryl O\'Connell',
    property: '81 Collier Drive, Brisbane VIC',
    date: '10-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'Pipeline',
    opportunity: 'Tenant Moving In',
    partner: 'Utilities',
    staff: 'Joe Jacobi',
    property: '81 Gordon Street, Adelaide VIC',
    date: '10-12-2024',
  },
  {
    name: 'Alexis West',
    status: 'Contacted',
    opportunity: 'Property Settled',
    partner: 'Mortgage Broking',
    staff: 'Phillip Brown',
    property: '79 Coronation Road, Brisbane QLD',
    date: '09-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'Gone Quiet',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Marc Reinger',
    property: '12 Ward Walks, Melbourne WA',
    date: '10-12-2024',
  },
  {
    name: 'Kelley Kemmer',
    status: 'Contacted',
    opportunity: 'Tenant Moving In',
    partner: 'Conveyancing',
    staff: 'Dr. Wilfred Beahan',
    property: '14 Milford Cliffs, Perth SA',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'Closed Won',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Ms. Juanita O\'Reilly',
    property: '94 Cristopher Burgs, Brisbane NSW',
    date: '10-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'Gone Quiet',
    opportunity: 'Property Unconditional',
    partner: 'Insurance',
    staff: 'Wade Donnelly DDS',
    property: '35 Krajcik Forge, Adelaide VIC',
    date: '09-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'Unqualified',
    opportunity: 'New Landlord Signup',
    partner: 'Insurance',
    staff: 'Dewey Schimmel',
    property: '63 Woodside Road, Adelaide QLD',
    date: '10-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Gone Quiet',
    opportunity: 'Offer Form',
    partner: 'Conveyancing',
    staff: 'Laura Murray',
    property: '93 Howell Meadows, Perth NSW',
    date: '10-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Pipeline',
    opportunity: 'Tenant Moving In',
    partner: 'Conveyancing',
    staff: 'Rene Waters II',
    property: '35 Emard Pike, Melbourne VIC',
    date: '10-12-2024',
  },
  {
    name: 'Debra Carroll',
    status: 'Unqualified',
    opportunity: 'Property Unconditional',
    partner: 'Conveyancing',
    staff: 'Garry Miller',
    property: '95 Lindgren Motorway, Perth NSW',
    date: '10-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'In Progress',
    opportunity: 'New Landlord Signup',
    partner: 'Mortgage Broking',
    staff: 'Wilbur Rogahn-Homenick',
    property: '42 Schuppe Squares, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'In Progress',
    opportunity: 'Offer Form',
    partner: 'Insurance',
    staff: 'Horace Collier V',
    property: '16 Lincoln Avenue, Sydney NSW',
    date: '09-12-2024',
  },
  {
    name: 'Terrance Ernser III',
    status: 'Unqualified',
    opportunity: 'New Landlord Signup',
    partner: 'Insurance',
    staff: 'Doyle Runte',
    property: '48 Ardella Cliff, Adelaide WA',
    date: '10-12-2024',
  },
  {
    name: 'Wendy Kirlin',
    status: 'Gone Quiet',
    opportunity: 'New Landlord Signup',
    partner: 'Mortgage Broking',
    staff: 'Marion Cormier',
    property: '79 Coronation Road, Brisbane QLD',
    date: '09-12-2024',
  },
  {
    name: 'Alexis West',
    status: 'Contacted',
    opportunity: 'New Landlord Signup',
    partner: 'Conveyancing',
    staff: 'Dr. Victor Braun',
    property: '95 Monroe Street, Sydney QLD',
    date: '10-12-2024',
  },
  {
    name: 'Dora Krajcik',
    status: 'Contacted',
    opportunity: 'Property Unconditional',
    partner: 'Conveyancing',
    staff: 'Muriel Armstrong',
    property: '37 Mayert Centers, Perth SA',
    date: '09-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'Unqualified',
    opportunity: 'New Landlord Signup',
    partner: 'Conveyancing',
    staff: 'Dustin Dach',
    property: '53 Cherry Close, Sydney QLD',
    date: '09-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Contacted',
    opportunity: 'Property Under Contract',
    partner: 'Utilities',
    staff: 'Justin Effertz V',
    property: '35 Krajcik Forge, Adelaide VIC',
    date: '10-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'Unqualified',
    opportunity: 'Property Settled',
    partner: 'Insurance',
    staff: 'Dr. Noah Fay IV',
    property: '53 Cherry Close, Sydney QLD',
    date: '10-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'New Opportunity',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Salvatore Ruecker I',
    property: '65 Ledner Bypass, Adelaide SA',
    date: '09-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'Unqualified',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Doris Leannon',
    property: '94 Rodrick Trail, Brisbane VIC',
    date: '09-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'Unqualified',
    opportunity: 'Offer Form',
    partner: 'Mortgage Broking',
    staff: 'Jose Abbott',
    property: '42 Schuppe Squares, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Sandy Prohaska Sr.',
    status: 'Gone Quiet',
    opportunity: 'Property Under Contract',
    partner: 'Insurance',
    staff: 'Dr. Sylvester Torp',
    property: '24 Rogelio Views, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Wendy Kirlin',
    status: 'Closed Won',
    opportunity: 'New Landlord Signup',
    partner: 'Conveyancing',
    staff: 'Nancy Mante',
    property: '94 Cristopher Burgs, Brisbane NSW',
    date: '10-12-2024',
  },
  {
    name: 'Dora Krajcik',
    status: 'Unqualified',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Ora Hudson',
    property: '71 Arvel Place, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Nicholas Kutch',
    status: 'New Opportunity',
    opportunity: 'Property Unconditional',
    partner: 'Utilities',
    staff: 'Rhonda Kris',
    property: '98 Coronation Avenue, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'New Opportunity',
    opportunity: 'New Property Listing',
    partner: 'Utilities',
    staff: 'Beth O\'Hara',
    property: '95 Jordi Way, Adelaide VIC',
    date: '10-12-2024',
  },
  {
    name: 'Minnie Botsford',
    status: 'Closed Won',
    opportunity: 'Offer Form',
    partner: 'Utilities',
    staff: 'Leah Hagenes',
    property: '65 Ledner Bypass, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Dr. Santos Osinski',
    status: 'Contacted',
    opportunity: 'Offer Form',
    partner: 'Mortgage Broking',
    staff: 'Ms. Mattie Kihn',
    property: '62 Leon Lights, Sydney NSW',
    date: '09-12-2024',
  },
  {
    name: 'Nicholas Kutch',
    status: 'Closed Won',
    opportunity: 'Tenant Moving In',
    partner: 'Insurance',
    staff: 'Sabrina Ferry',
    property: '99 W High Street, Melbourne VIC',
    date: '10-12-2024',
  },
  {
    name: 'Kelley Kemmer',
    status: 'Closed Won',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Danielle Jaskolski',
    property: '52 Rectory Close, Sydney NSW',
    date: '10-12-2024',
  },
  {
    name: 'Toby Jacobson',
    status: 'Closed Lost',
    opportunity: 'Tenant Move-out',
    partner: 'Conveyancing',
    staff: 'Ebony Lynch',
    property: '27 Piper Parks, Melbourne SA',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'Unqualified',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Sally Carroll PhD',
    property: '48 Ardella Cliff, Adelaide WA',
    date: '10-12-2024',
  },
  {
    name: 'Dora Krajcik',
    status: 'Unqualified',
    opportunity: 'New Property Listing',
    partner: 'Mortgage Broking',
    staff: 'Allen Hudson',
    property: '40 Hilll Wells, Adelaide QLD',
    date: '10-12-2024',
  },
  {
    name: 'Wendy Kirlin',
    status: 'Uncontactable',
    opportunity: 'New Property Listing',
    partner: 'Mortgage Broking',
    staff: 'Terrell Hills',
    property: '93 Howell Meadows, Perth NSW',
    date: '10-12-2024',
  },
  {
    name: 'Dr. Santos Osinski',
    status: 'Uncontactable',
    opportunity: 'Tenant Moving In',
    partner: 'Insurance',
    staff: 'Marion Parisian',
    property: '52 Conor Crescent, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Joey Prohaska',
    status: 'Pipeline',
    opportunity: 'New Landlord Signup',
    partner: 'Utilities',
    staff: 'Lamar Pollich',
    property: '71 Derwent Close, Adelaide NSW',
    date: '09-12-2024',
  },
  {
    name: 'Dora Krajcik',
    status: 'Gone Quiet',
    opportunity: 'Property Settled',
    partner: 'Insurance',
    staff: 'Richard Zemlak DVM',
    property: '3 Estella Islands, Perth QLD',
    date: '10-12-2024',
  },
  {
    name: 'Kelley Kemmer',
    status: 'Contacted',
    opportunity: 'Offer Form',
    partner: 'Conveyancing',
    staff: 'Carla Hoeger',
    property: '39 Adah Row, Sydney SA',
    date: '10-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Gone Quiet',
    opportunity: 'New Property Listing',
    partner: 'Mortgage Broking',
    staff: 'Dr. Leon Swift',
    property: '87 Murray Spring, Adelaide NSW',
    date: '10-12-2024',
  },
  {
    name: 'Zachary Schulist MD',
    status: 'Contacted',
    opportunity: 'Offer Form',
    partner: 'Insurance',
    staff: 'Mrs. Vicky Mohr',
    property: '71 Arvel Place, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Closed Lost',
    opportunity: 'Property Settled',
    partner: 'Insurance',
    staff: 'Mathew Monahan',
    property: '65 Ledner Bypass, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'New Opportunity',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Vicki Hills II',
    property: '35 Emard Pike, Melbourne VIC',
    date: '10-12-2024',
  },
  {
    name: 'Traci Carroll',
    status: 'New Opportunity',
    opportunity: 'New Property Listing',
    partner: 'Mortgage Broking',
    staff: 'Nick Hettinger',
    property: '11 Raina Hollow, Perth VIC',
    date: '09-12-2024',
  },
  {
    name: 'Terrance Ernser III',
    status: 'Pipeline',
    opportunity: 'Property Unconditional',
    partner: 'Conveyancing',
    staff: 'Justin Champlin',
    property: '46 W Park Street, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Sandy Prohaska Sr.',
    status: 'Closed Lost',
    opportunity: 'Property Settled',
    partner: 'Insurance',
    staff: 'Ethel Lueilwitz',
    property: '24 Rogelio Views, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Doyle Gislason',
    status: 'Pipeline',
    opportunity: 'Offer Form',
    partner: 'Utilities',
    staff: 'Allen Kovacek',
    property: '53 W River Road, Brisbane SA',
    date: '10-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'New Opportunity',
    opportunity: 'Tenant Moving In',
    partner: 'Utilities',
    staff: 'Marianne Nicolas',
    property: '98 Brook Road, Sydney QLD',
    date: '09-12-2024',
  },
  {
    name: 'Leslie Von',
    status: 'Uncontactable',
    opportunity: 'New Landlord Signup',
    partner: 'Insurance',
    staff: 'Carlton Moen',
    property: '39 Adah Row, Sydney SA',
    date: '10-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'Closed Won',
    opportunity: 'Property Settled',
    partner: 'Utilities',
    staff: 'Carl Rau IV',
    property: '46 W Park Street, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Kelley Kemmer',
    status: 'Closed Won',
    opportunity: 'Tenant Moving In',
    partner: 'Conveyancing',
    staff: 'Marshall Upton',
    property: '98 Coronation Avenue, Perth VIC',
    date: '09-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'Closed Won',
    opportunity: 'Tenant Move-out',
    partner: 'Utilities',
    staff: 'Miriam Ullrich',
    property: '81 Collier Drive, Brisbane VIC',
    date: '09-12-2024',
  },
  {
    name: 'Dr. Santos Osinski',
    status: 'Unqualified',
    opportunity: 'Property Unconditional',
    partner: 'Insurance',
    staff: 'Mr. Ramiro Wisoky II',
    property: '26 Cavendish Road, Adelaide WA',
    date: '10-12-2024',
  },
  {
    name: 'Debra Carroll',
    status: 'New Opportunity',
    opportunity: 'Tenant Moving In',
    partner: 'Utilities',
    staff: 'Darnell Roberts',
    property: '94 Cristopher Burgs, Brisbane NSW',
    date: '10-12-2024',
  },
  {
    name: 'Jay Fisher',
    status: 'Gone Quiet',
    opportunity: 'New Property Listing',
    partner: 'Utilities',
    staff: 'Ms. Faye Ruecker',
    property: '85 Braden Fork, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Minnie Botsford',
    status: 'New Opportunity',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Teresa Hane',
    property: '79 Jeffery Port, Brisbane QLD',
    date: '10-12-2024',
  },
  {
    name: 'Wade Gutkowski',
    status: 'In Progress',
    opportunity: 'Tenant Move-out',
    partner: 'Utilities',
    staff: 'Mrs. Frances Runolfsson',
    property: '11 Raina Hollow, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Eva Moore',
    status: 'Closed Won',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Dave Runte',
    property: '8 Maxime Causeway, Perth WA',
    date: '10-12-2024',
  },
  {
    name: 'Mabel Bednar',
    status: 'In Progress',
    opportunity: 'New Landlord Signup',
    partner: 'Insurance',
    staff: 'Mrs. Iris Bartell',
    property: '83 Fern Centers, Melbourne QLD',
    date: '09-12-2024',
  },
  {
    name: 'Rodney Schmitt',
    status: 'Gone Quiet',
    opportunity: 'Property Settled',
    partner: 'Mortgage Broking',
    staff: 'Elbert Johnson',
    property: '58 Kingfisher Close, Melbourne WA',
    date: '10-12-2024',
  },
  {
    name: 'Kelley Adams',
    status: 'New Opportunity',
    opportunity: 'Tenant Moving In',
    partner: 'Mortgage Broking',
    staff: 'Wayne Emmerich',
    property: '37 Delphia Green, Brisbane NSW',
    date: '10-12-2024',
  },
  {
    name: 'Dominick Gusikowski DVM',
    status: 'In Progress',
    opportunity: 'Offer Form',
    partner: 'Insurance',
    staff: 'Marcus Ryan',
    property: '16 Batz Branch, Brisbane WA',
    date: '09-12-2024',
  },
  {
    name: 'Mrs. Norma Metz',
    status: 'Gone Quiet',
    opportunity: 'New Landlord Signup',
    partner: 'Mortgage Broking',
    staff: 'Mr. Antonio Connelly',
    property: '71 Derwent Close, Adelaide NSW',
    date: '10-12-2024',
  },
  {
    name: 'Luz Bergstrom',
    status: 'Pipeline',
    opportunity: 'New Property Listing',
    partner: 'Conveyancing',
    staff: 'Karen Cronin',
    property: '46 W Park Street, Perth VIC',
    date: '09-12-2024',
  },
  {
    name: 'Dr. Rafael Torphy',
    status: 'Pipeline',
    opportunity: 'Property Under Contract',
    partner: 'Conveyancing',
    staff: 'Ralph Daugherty',
    property: '48 Ardella Cliff, Adelaide WA',
    date: '10-12-2024',
  },
  {
    name: 'Tommie Kris V',
    status: 'Contacted',
    opportunity: 'Property Under Contract',
    partner: 'Mortgage Broking',
    staff: 'Hilda Dooley PhD',
    property: '52 Rectory Close, Sydney NSW',
    date: '10-12-2024',
  },
  {
    name: 'Brett Wintheiser I',
    status: 'In Progress',
    opportunity: 'Property Under Contract',
    partner: 'Mortgage Broking',
    staff: 'Kristine Yundt',
    property: '63 Woodside Road, Adelaide QLD',
    date: '10-12-2024',
  },
  {
    name: 'Jasmine Koss',
    status: 'Contacted',
    opportunity: 'Tenant Moving In',
    partner: 'Conveyancing',
    staff: 'Lionel Gleichner',
    property: '24 Rogelio Views, Adelaide SA',
    date: '10-12-2024',
  },
  {
    name: 'Ms. Charlotte Carroll',
    status: 'New Opportunity',
    opportunity: 'Tenant Move-out',
    partner: 'Insurance',
    staff: 'Jeffrey Grimes',
    property: '46 W Park Street, Perth VIC',
    date: '10-12-2024',
  },
  {
    name: 'Jon Kuvalis V',
    status: 'Closed Lost',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Connie Sauer',
    property: '3 Estella Islands, Perth QLD',
    date: '10-12-2024',
  },
  {
    name: 'Karla Renner',
    status: 'Gone Quiet',
    opportunity: 'Property Unconditional',
    partner: 'Conveyancing',
    staff: 'Guadalupe Bernier DVM',
    property: '79 Coronation Road, Brisbane QLD',
    date: '10-12-2024',
  },
  {
    name: 'Gordon Beahan',
    status: 'Closed Lost',
    opportunity: 'Tenant Moving In',
    partner: 'Conveyancing',
    staff: 'Doug Ondricka-Littel',
    property: '94 Rodrick Trail, Brisbane VIC',
    date: '10-12-2024',
  },
  {
    name: 'Kristy Blanda',
    status: 'Uncontactable',
    opportunity: 'Property Under Contract',
    partner: 'Mortgage Broking',
    staff: 'Andrea Heidenreich',
    property: '19 Adams Avenue, Perth QLD',
    date: '09-12-2024',
  },
  {
    name: 'Dr. Mike O\'Reilly',
    status: 'In Progress',
    opportunity: 'Tenant Move-out',
    partner: 'Conveyancing',
    staff: 'Pauline Hegmann',
    property: '72 Golda Fort, Brisbane NSW',
    date: '09-12-2024',
  },
  {
    name: 'Howard Reinger',
    status: 'Unqualified',
    opportunity: 'New Landlord Signup',
    partner: 'Conveyancing',
    staff: 'Jay Wyman',
    property: '3 Estella Islands, Perth QLD',
    date: '10-12-2024',
  },
  {
    name: 'Dolores Flatley-Moore',
    status: 'Closed Won',
    opportunity: 'New Property Listing',
    partner: 'Insurance',
    staff: 'Leon Kuvalis',
    property: '98 Brook Road, Sydney QLD',
    date: '10-12-2024',
  },
  {
    name: 'Wanda Lehner V',
    status: 'Closed Won',
    opportunity: 'Offer Form',
    partner: 'Conveyancing',
    staff: 'Kathryn Champlin',
    property: '35 Krajcik Forge, Adelaide VIC',
    date: '10-12-2024',
  },
  {
    name: 'Mr. Guillermo Weissnat',
    status: 'Pipeline',
    opportunity: 'Offer Form',
    partner: 'Utilities',
    staff: 'Mr. Allan Anderson',
    property: '53 Cherry Close, Sydney QLD',
    date: '10-12-2024',
  },
]
