import React from 'react'

import { Box, Typography } from '@mui/material'

import Modal from '../Modal'

interface Props {
  open: boolean
  onClose: (event: unknown, reason: string) => void
  onCreate: () => void
}

export default function CreateLeadModal({
  open,
  onClose,
  onCreate,
}: Props): React.ReactElement {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Create Lead'}
      closeButtonVisible={true}
      footerVisible={true}
      confirmText={'Create Lead'}
      cancelText={'Cancel'}
      onConfirm={onCreate}
      onCancel={() => onClose(null, 'CancelClick')}
      className={'mt-20 !w-[900px]'}
    >
      <Box p={3} pt={1}>
        <Typography>{'Content'}</Typography>
      </Box>
    </Modal>
  )
}
